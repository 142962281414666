
<template>
<div class="container-fluid mt-3">


              <v-alert
                v-if="data"
                prominent
                border="left"
                color="warning"
                outlined
              >
                <v-row align="center">
                  <v-col>
                    {{this.data.message}}
                  </v-col>
                  <v-col class="shrink">
                    <v-btn small text @click="$router.push({ name: 'feed'})">{{ $t('feed') }}</v-btn>
                  </v-col>
                </v-row>
              </v-alert>

<v-card v-if="order">
  <v-card-title>
    Order id: {{order.id}}
    <v-spacer/>
    {{order.vendor_name}}
  </v-card-title>
  <v-card-text>
 
    <div v-for="(item,index) in this.order.items" :key="index">
    {{item.name}} | Quantity {{item.quantity}} | Price {{item.price | currency}} | Total: {{item.total_price | currency}}
    </div>  

  </v-card-text>
  <v-card-actions>

    <v-btn @click="authorize(true)" :disabled="paymentProcessing" small color="green">
      Auhorize payment
    </v-btn>
<v-spacer/>
    <v-btn @click="authorize(false)" :disabled="paymentProcessing" x-small color="red">
      Decline payment
    </v-btn>
  </v-card-actions>

</v-card>






</div>
</template>


<script>

import { mapGetters } from 'vuex'


const qs = (params) => Object.keys(params).map(key => `${key}=${params[key]}`).join('&')

export default {
  middleware: ['auth','user'],

  async beforeRouteEnter (to, from, next) {
    try { 

      const { data } = await this.$http.post(`/api/orders/${to.params.id}/payment/verify?${qs(to.query)}`)
      next( vm => {  
        vm.success = data.message,
        vm.paymentUrl = data.url
        vm.order = data.order
        })
    } catch (e) {
      console.log(e.data.message);
      next( vm => { vm.error = e.data.message })
    }
  },

  components:{

  },
  computed:{
        ...mapGetters({
          user: 'auth/user'
        }),
  },
   data: () => ({

        loading: false,
        data: null,
        paymentUrl: null,
        order: null,
        paymentProceed: false,
        paymentProcessing: false,
        validPaymentInputs: false,
        error: '',
        success: '',
        orderMessageDialog: false,
        orderResponse: null,
        orderError: false       
    }),
    mounted() {
     //this.getPaymentIntent();
    },
    watch: {

             
    },  
    methods: {
      authorize($param){
        this.paymentProcessing = true;
        this.loading = true;

        let uri = this.paymentUrl;
        //console.log(uri);
        this.$http.post(uri,{authorize: $param}).then((response) => {
            this.data = response.data,
            this.loading = false

        });
      },

    },

}
</script>







